import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import Bugsnag from '@bugsnag/js'

import { errorToast } from '../../app/lib/toast'
import { postOAuthToken, updateUserData } from '../../app/lib/api'
import { setUserAuth } from '../../app/features/user/userSlice'

export default function EmailForm ({ onNextStep }) {
  const dispatch = useDispatch()
  const idRef = useRef()
  const [id, setId] = useState('')
  const [loading, setLoading] = useState(false)

  function onChange (e) {
    setId(e.target.value)
  }

  async function onSubmit (event) {
    event.preventDefault()

    try {
      setLoading(true)

      const response = await postOAuthToken({ id })

      if (response?.token_type === 'bearer') {
        await dispatch(setUserAuth(response))
        await updateUserData(response, dispatch)
        return onNextStep()
      } else {
        console.error(response)
        errorToast(response?.error_description)
      }
    } catch (error) {
      console.error(error)
      errorToast()
      Bugsnag.notify(error)
    }

    setLoading(false)
  }

  // Allow valid id when the browser doesn't support the validity api
  const validId = !idRef?.current?.validity || idRef.current?.validity?.valid

  return (
    <form onSubmit={onSubmit} className={loading ? 'loading' : ''}>
      <p>Boas-vindas ao Ingredion Training! Para continuar, insira sua matrícula.</p>
      <input type='text' placeholder='Matrícula' autoFocus required value={id} onChange={onChange} disabled={loading}
             ref={idRef} />
      <button disabled={loading || !id || !validId}>Continuar</button>
    </form>
  )
}
