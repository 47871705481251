import Head from 'next/head'
import { useRouter } from 'next/router'

import LoginForm from '../components/login-form'
import { useDispatch } from 'react-redux'
import { startEngine } from '../app/features/engine/engineSlice'

export default function Login ({ fullScreenHandle }) {
  const router = useRouter()
  const dispatch = useDispatch()

  async function goToGame () {
    try {
      await fullScreenHandle.enter()
      await dispatch(startEngine())
    } catch (error) {
      // Ignore errors if we cannot enter fullscreen
      console.log(error)
    }

    await router.push('/')
  }

  return (
    <div>
      <Head>
        <title>Ingredion Training - Login</title>
        <meta name='description' content='Ingredion Training' />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <LoginForm onReady={goToGame} />
    </div>
  )
}
