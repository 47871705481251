import { useState } from 'react'

import IdForm from './step-0-id'
import FullNameForm from './step-1-fullname'

import styles from './styles.module.scss'

export default function LoginForm ({ onReady }) {
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState(0)

  function nextStep (data) {
    setFormData(data)
    setStep(step + 1)
    if (step === 1) {
      onReady()
    }
  }

  return (
    <div className='center'>
      <div className={`small box with-logo ${styles.box}`}>
        {step === 0 && <IdForm onNextStep={nextStep} />}
        {step === 1 && <FullNameForm onNextStep={nextStep} formData={formData} />}
      </div>
    </div>
  )
}
