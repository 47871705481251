import Bugsnag from '@bugsnag/js'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { errorToast } from '../../app/lib/toast'
import { patchUser } from '../../app/lib/api'
import { logoutReload, selectUserAuth, selectUserData } from '../../app/features/user/userSlice'


const UNIDADES = ['', 'Alcantara', 'Balsa Nova', 'Cabo de Santo Agostinho', 'Mogi Guaçu', 'Escritório Central']

//const AREAS = ['', 'Processo Seco', 'Processo Úmido', 'Refinaria', 'Manutenção', 'Logística', 'Controle de Qualidade', 'Administrativo']
const AREAS = {
    '': [''],
    'Escritório Central': ['', 'Escritório Central'],
    'Mogi Guaçu': ['', 'Administrativo', 'Engenharia', 'Logística', 'Manutenção', 'Processo Úmido', 'Processo Seco', 'Qualidade', 'Refinaria', 'Utilidades'],
    'Cabo de Santo Agostinho': ['', 'Administrativo', 'Engenharia', 'Logística', 'Manutenção', 'Processo Úmido', 'Processo Seco', 'Qualidade', 'Refinaria', 'Utilidades'],
    'Balsa Nova': ['', 'Administrativo', 'Engenharia', 'Logística', 'Manutenção', 'Processo Úmido', 'Processo Seco', 'Qualidade', 'Refinaria', 'Utilidades'],
    'Alcantara': ['', 'Administrativo', 'Engenharia', 'Logística', 'Manutenção', 'Produção', 'Qualidade', 'Utilidades']
  };


export default function FullNameForm ({ onNextStep }) {
  const dispatch = useDispatch()
  const user = useSelector(selectUserData)
  const auth = useSelector(selectUserAuth)
  const [fullname, setFullName] = useState(user?.fullname ?? '')
  const [area, setArea] = useState(user?.area ?? '')
  const [unidade, setUnidade] = useState(user?.unidade ?? '')
  const [loading, setLoading] = useState(false)

  function onChangeFullName (e) {
    setFullName(e.target.value)
  }

  function onChangeArea (e) {
    setArea(e.target.value)
  }

  function onChangeUnidade (e) {
    setUnidade(e.target.value)
    setArea("")
  }

  async function onSubmit (event, videos) {
    event.preventDefault()

    try {
      setLoading(true)

      const response = await patchUser(auth, { fullname, area, unidade })

      if (response?.ok) {
        return onNextStep()
      } else {
        console.error(response)
        errorToast(response?.message)
      }
    } catch (error) {
      console.error(error)
      errorToast()
      Bugsnag.notify(error)
    }

    setLoading(false)
  }

  return (
    <form onSubmit={onSubmit} className={loading ? 'loading' : ''}>
      <p>Escreva seu nome completo e selecione sua área e unidade:</p>
      <input type='text' placeholder='Nome completo' autoFocus required value={fullname} onChange={onChangeFullName}
             disabled={loading} />
      <select value={unidade} onChange={onChangeUnidade} disabled={loading}>
        {UNIDADES.filter((a) => !user.unidade || a).map((option) => <option key={option} value={option}>{option || "-- Selecione uma unidade --"}</option>)}
      </select>
      <select value={area} onChange={onChangeArea} disabled={loading}>
        {AREAS[unidade].filter((a) => !user.area || a).map((option) => <option key={option} value={option}>{option || "-- Selecione um área --"}</option>)}
      </select>
      <button disabled={loading || !fullname || !area || !unidade}>Continuar</button>
      <button type='button' onClick={() => dispatch(logoutReload())}>Entrar com outra conta</button>
    </form>
  )
}
